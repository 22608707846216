<div [dir]="closeDir" class="add-report-dialog">
  <button (click)="close()" class="close_button" mat-icon-button>
    <mat-icon class="close_icon">close</mat-icon>
  </button>
  <div [dir]="transDir.mainDirection" class="content">
    <span class="title">{{ i18nPrefix + "title" | translate }}</span>
    <form
      [formGroup]="validateForm"
      class="add-report-form full-height full-width"
      [dir]="transDir.mainDirection"
    >
      <mat-form-field appearance="fill" class="full-width">
        <textarea
          matInput
          [class.text-ltr]="transDir.mainDirection === 'ltr'"
          [dir]="transDir.mainDirection"
          formControlName="message"
          placeholder="{{ i18nPrefix + 'report_placeholder' | translate }}"
        ></textarea>
      </mat-form-field>
    </form>
    <button
      type="submit"
      class="send-button full-width"
      [mat-dialog-close]="submitForm()"
      [disabled]="!validateForm.valid"
      mat-flat-button
      color="primary"
    >
      {{ i18nPrefix + "send_btn" | translate }}
    </button>
  </div>
</div>
