import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { MatDialogRef } from '@angular/material/dialog';
import { BasePage } from '../../../../shared';

@Component({
  selector: 'wi-mobi-send-message-dialog',
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss']
})
export class SendMessageDialogComponent extends BasePage implements OnInit {
  validateForm!: FormGroup;
  i18nPrefix = 'Features.Products.Dialogs.SendText.';
  closeDir: Direction = this.transDir.mainDirection === 'rtl' ? 'ltr' : 'rtl';

  constructor(private _formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<SendMessageDialogComponent>) {
    super();

  }

  submitForm() {
    if (!this.validateForm.valid) {
      return;
    }
    for (const I in this.validateForm.controls) {
      if (Object.prototype.isPrototypeOf.call(this.validateForm.controls, I)) {
        this.validateForm.controls[I].markAsDirty();
        this.validateForm.controls[I].updateValueAndValidity();
      }
    }

    return { ...this.validateForm.getRawValue() };
  }

  ngOnInit(): void {
    this.validateForm = this._formBuilder.group({
      message: new FormControl('', [
        Validators.required
      ])
    });
  }

  close() {
    this.dialogRef.close();
  }
}
